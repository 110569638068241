<template>
  <v-toolbar 
    flat 
    color="white"
    class="toolbar"
  >
    <slot />
    <slot name="select" v-if="!divHidden"/>
    <!-- <v-col cols="6" sm="4" md="5" v-if="!divHidden">
    </v-col> -->
    <v-spacer></v-spacer>
    <v-row class="justify-end">
      <slot name="btnSelect"/>
      <v-col cols="12" md="3" v-if="this.configFilter.disableTextField !== true">
        <v-text-field
          append-icon="search"
          :label="labelTextField"
          single-line
          hide-details
          v-on:keyup.enter="$emit('search', search)"
          v-model="search"
          color="primary" />
        <!-- <BrSearchDataTable @search="buscar" /> -->
      </v-col>

      <v-col cols="1" v-if="activateFilter">
        <BrFilter :configFilter="configFilter" 
          :listaSelect="configFilter.listaSelect" 
          :getItens="configFilter.getItens" 
          :jsonData="configFilter.jsonData" 
          :addlFilter="addlFilter" 
          :disableLineFilter="disableLineFilter" 
        />
      </v-col>

      <v-col cols="1" v-if="activateUpdate" class="col-update">
        <v-btn text icon @click="update()" :loading="loadingUpdate">
          <v-icon>update</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-col cols="12"  md="3" v-if="hasSelect">
      <v-select
        :items="itemsSelect"
        :label="labelSelects"
        single-line
        hide-details
        v-model="selectFilter"
        :return-object="true"></v-select>
    </v-col>
    <slot name="selectTypePagamentos" />

    <!-- <v-btn 
      @click="switchStatus"
      v-if="this.configFilter.activeSwitchStatus"
      tile
      outlined
      small
      :color="getColorSwitch(this.configFilter.isStatus)">
      {{ this.configFilter.switchStatusName }}
    </v-btn>

    <BrFilter :configFilter="configFilter" 
        :listaSelect="configFilter.listaSelect" 
        :getItens="configFilter.getItens" 
        :jsonData="configFilter.jsonData" 
        :addlFilter="addlFilter" 
        :disableLineFilter="disableLineFilter" 
      />
    <v-btn text icon @click="update()" :loading="loadingUpdate">
      <v-icon>update</v-icon>
    </v-btn> -->
  </v-toolbar>
</template>

<script>
import Events from '@/core/service/events'
import { size } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: `BrToolBar`,
  components: {
    BrFilter: () => import('@/views/components/filter')
  },
  props: {
    divHidden:{
      type: Boolean,
      default: () => false
    },
    itemsSelect: {
      type: Array,
      default: () => []
    },
    disableLineFilter:{
      type: Boolean,
      default: true
    },
    labelSelects: {
      type: String,
      default: 'Selecione'
    },
    configFilter: {
      type: Object,
      default: () => {}
    },

    addlFilter: {
      type: Boolean,
      default: false
    },

    labelTextField: {
      type: String,
      default: 'Busca por nome:'
    },

    activateFilter: {
      type: Boolean,
      default: true
    },

    activateUpdate: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    selectFilter(val) {
      Events.$emit('toolbar::selectFilter', val)
    }
  },

  data: () => ({
    search: '',
    selectFilter: '',
    loadingUpdate: false
  }),

  computed: {
    ...mapGetters('filter', ['objFilter']),
    hasSelect() {
      return size(this.itemsSelect) ? !!1 : !!0
    },

    variables: () => variables,
  },

  methods: {
    ...mapActions('filter', ['clearObjFilter']),
    update () {
      Events.$emit('toolbar::update')
    },

    switchStatus () {
      this.configFilter.switchStatus()
      this.update()
    },

    getColorSwitch (status) {
      switch (status) {
        case false:
          return variables.colorAccent
        case true:
          return variables.colorPrimary
      }
    }
  },
  beforeDestroy () {
    this.clearObjFilter()
  },
}
</script>


<style lang="scss" scoped>
.toolbar::v-deep {
  height: auto !important;

  .v-toolbar__content {
    height: auto !important;
  }
}

.col-update {
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>